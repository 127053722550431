pre {
  margin: 24px auto;
  padding: 16px;
  overflow: auto;
  direction: ltr;
  max-width: calc(100vw - 32px);
  border-radius: 4px;
  background-color: #272c34;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 960px) {
    max-width: calc(100vw - 32px - 16px);
  }
}

code {
  text-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  padding: 0 3px;
  direction: ltr;
  font-size: 0.85em;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  line-height: 1.4;
  border-radius: 2px;
  background-color: rgba(255, 229, 100, 0.2);
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 0.9em;
  color: #fff;
  line-height: 1.5;
  background-color: #272c34;
  background: none;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

.token.keyword {
  color: #66d9ef;
}

.token.code {
  color: #e6db74;
}

.token.string {
  color: #a6e22e;
}

.clipboard {
  position: relative;
  left: 250px;
  cursor: pointer;
  color: #fff;
  bottom: 15px;
}
.clipboard-2 {
  position: relative;
  bottom: 15px;
  color: #fff;
  // font-size: 4px;
  cursor: pointer;
  right: 15px;
  z-index: 10000000;
}
