.cashback-container {
  border: 1px solid transparent;
  padding: 20px;
  background-color: #6495ed;
  width: 25%;
  min-width: 300px;
  max-width: 450px;
}

p {
  font-size: 1em;
  font-weight: normal;
  color: #000;
}

.input-group-text {
  display: flex;
  justify-content: flex-end;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
